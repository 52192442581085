  /*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand&display=swap');

.tox-notifications-container {
  display: none !important;
}

@font-face {
  font-family: "Montserrat";
  src: url(assets/font/Montserrat-Regular.ttf);
}

* {
  outline: none;
  margin: 0px;
  padding: 0px;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

html, body {
  overflow-x: hidden;
}

body {
  position: relative;
}

.gras {
  font-weight: bold;
}

.italique {
  font-style: italic;
}


.no-select {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.alert-logo-enm {
  .alert-wrapper {
    width: max-content;
    min-width: unset;
    max-width: 70%;

    .alert-button-group {
      justify-content: space-evenly;
    }
  }
}

.toast-notification-bonus {
  &::part(container) {

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);    
    border-radius: var(--border-radius);
    width: var(--width);
    min-width: var(--min-width);
    max-width: var(--max-width);
    height: var(--height);
    min-height: var(--min-height);
    max-height: var(--max-height);
    border-width: var(--border-width);
    border-style: var(--border-style);
    border-color: var(--border-color);
    background: var(--background);
    box-shadow: var(--box-shadow);
  }
}


@media (max-height : 700px) {
  .toast-notification-desactive::part(container) {
    font-size: 12px !important;
  }

}


.btn-alert {
  border-radius: 5px !important;
  min-width: 80px !important;

  span{
    justify-content: center !important;
    text-transform: capitalize !important;
  }
}

.btn-yes {
  background-color: var(--ion-color-primary) !important;
  color: white !important;
}

.btn-no {
  background: var(--ion-color-danger) !important;
  color: white !important;
}


.table-pagination {
  width: 100%;
  display: flex;
  flex-direction: row;

  .table-pagination-button {
    display: flex;
    flex-direction: column;
    width: 30px;
    height: 30px;
    outline: none;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-size: 15px;
    justify-items: center;
    outline: none;
    background: white;
    border: 1px solid var(--ion-color-medium);
    color: var(--ion-color-medium);
    margin: 0px 3px;
    transition: 0.3s;
    border-radius: 5px;

    &:first-of-type {
      margin-left: 16px;
    }

    &:hover {
      background-color: var(--ion-color-primary);
      color: white;
      border: 1px solid var(--ion-color-primary);
    }
  }

  .table-pagination-button-selected {
    background-color: var(--ion-color-primary);
    color: white;
    border: 1px solid var(--ion-color-primary);
  }
}

// CSS du module Quiz innerHTML


.quiz-introduction {
  padding: 50px;

  p {
    font-size: 22px;
    width: 100%;
    margin: 20px auto;
    color: white;
    text-align: left;

    &:first-of-type {
      margin-top: 0px;
    }

    &:last-of-type {
      margin-bottom: 0px;
    }

    .gras {
      font-weight: bold;
    }
  }

  @media (max-width : 800px) {
    padding: 30px;

    p {
      font-size: 16px;
    }   
  }

  @media (max-width : 500px) {
    padding: 15px;

    p {
      font-size: 14px;
    }   
  }
}

////////////// TOOLTIP //////////////

.tooltip {
  cursor: pointer;
  position: relative;
  display: inline-block;
  //AP//
  color:#fff;
  //AP//

  &:hover {
    .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
  }

  .tooltiptext {
    width: max-content;
    text-align: left;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.7em;
    visibility: hidden;
    background-color: rgba($color: #000, $alpha: 0.9);
    color: #fff;
    font-weight: normal;
    border-radius: 6px;
    padding: 5px 5px;
    z-index: 9999999991;
    bottom: 125%;
    opacity: 0;
    transition: opacity 0.3s;
    max-width: 50vw;
    text-transform: none;
   

    .tooltip-title {
      font-weight: bold;
      font-size: 0.8em;
    }

    p {
      font-size: 0.7em;
    }

    
  
    ul {
      list-style: disc;
      list-style-position: outside;
      width: calc(100% - 25px);
      display: block;
      margin-left: 25px;
      margin-top: 5px;

      p {
        font-size: 0.7em;
      }
    }

    // ENCOCHE 
    
    // &::after {
    //   content: "";
    //   position: absolute;
    //   top: 100%;
    //   left: 50%;
    //   margin-left: -5px;
    //   border-width: 5px;
    //   border-style: solid;
    //   border-color: #555 transparent transparent transparent;
    // }
  }
}

// Tooltip header
.tooltip-deconnexion,.tooltip-intro,.tooltip-notification,.tooltip-admin{
  .tooltiptext {
    left: 0px;
    transform: translateX(0%);
    bottom: unset;
    top: 35px;
  }
}


.tooltip-quiz-181 {
  @media (max-width : 385px) {
    .tooltiptext {
      left: 0px;
      transform: translateX(-70%);
    }
  }
}

// Tooltip quiz criteres font-size
.tooltip-critere-181,.tooltip-critere-186,.tooltip-critere-187,.tooltip-critere-188 {
  .tooltiptext {
    bottom: unset;
    top: 120%;
    max-width: 65vw;

    .tooltip-title {
      font-size: 0.9em;
    }
  
    p, ul li p {
      font-size: 0.8em;
    }
  }
}

.tooltip-critere-181 {
  @media (max-width : 480px) {
    .tooltiptext {
      left: 0px;
      transform: translateX(-70%);
    }
  }
}

.tooltip-critere-186 {
  @media (max-width : 675px) {
    .tooltiptext {
      left: 0px;
      transform: translateX(-70%);
    }
  }
  
  @media (max-width : 365px) {
    .tooltiptext {
      transform: translateX(-75%);
    }
  }
}

.tooltip-critere-187 {
  @media (max-width : 480px) {
    .tooltiptext {
      left: 0px;
      transform: translateX(-70%);
    }
  }
}

.tooltip-critere-188 {
  @media (max-width : 480px) {
    .tooltiptext {
      left: 0px;
      transform: translateX(-70%);
    }
  }
}



app-root {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;

  // backgroundImage géré en Javascript
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;
}

app-admin {
  overflow: auto;
  z-index: 1;
}

app-accueil {
  overflow: auto;
  z-index: 1;
}

app-error {
  z-index: 1;
}

app-logged {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;

  app-page {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .activitie_container {
      // Container d'une seul activité
      width: 70%;
      display: flex;
      flex-direction: column;
      margin: 0px auto;
      position: relative;
      height: 100%;

      @media (max-width: 650px) {
        width: 90%;
      }

      .activitie_description {
        display: flex;
        flex-direction: column;
        max-width: 70%;
        margin: 0px auto 0px auto;

        @media (max-width: 500px) {
          max-width: 100%;
        }

        h3 {
          color: white;
          font-weight: bold;
          font-size: 30px;
          text-align: center;

          @media (max-width: 1200px) {
            font-size: 20px;
          }

          @media (max-width: 850px), (max-height: 850px) {
            font-size: 16px;
            margin-top: 0px;
            margin-bottom: 0px !important;
          }

          @media (max-width: 650px) {
            margin-bottom: 0px;
          }
        }

        p {
          color: white;
          font-size: 25px;
          text-align: center;

          @media (max-width: 1200px) {
            font-size: 16px;
          }

          @media (max-width: 850px), (max-height: 850px) {
            font-size: 14px;
            margin: 0px !important;
          }

          @media (max-width: 650px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  // Selectionne le premier enfant direct de App-page peut importe son type
  app-page > *:first-child {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

#intro-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .intro_container {
    display: flex;
    position: relative;
    flex-direction: column;
    margin: auto auto;
    width: 60%;
    justify-content: center;
    align-items: center;
    padding: 50px;
    box-sizing: content-box;

    @media (max-width: 650px) {
      padding: 20px;
    }

    p {
      color: white;
      text-align: center;
      font-size: 20px;

      @media (max-width: 1200px), (max-height: 625px) {
        font-size: 16px;
      }

      @media (max-width: 650px) {
        font-size: 12px;
      }
    }

    img {
      width: 10%;
      padding: 40px;
      box-sizing: content-box;
      min-width: 100px;

      @media (max-width: 500px) {
        min-width: 75px;
      }
    }

    .btn-start-activitie {
      position: absolute;
      bottom: -20%;
      background: black;
      border: 2px solid white;
      border-radius: 7px;
      left: 50%;
      transform: translateX(-50%);
      text-decoration: none;
      color: white;
      padding: 5px 30px;
      font-size: 25px;
      cursor: pointer;
      outline: none;

      @media (max-width: 650px) {
        font-size: 14px;
      }
    }
  }
}

app-admin {
  height: 100%;
  main {
    display: flex;
    flex-direction: column;
    width: calc(100% - 225px);
    margin-left: auto;
    overflow: auto;
    padding-bottom: 100px;
    height: 100%;
    transition: 0.3s;

    #container {
      width: 85%;
      margin: 0px auto;
      display: flex;
      flex-direction: column;

      h1 {
        margin: 20px 0px;
      }

      .box {
        display: flex;
        flex-direction: column;
        border: 1px solid #dbdbdb;
        border-radius: 5px;

        .box-title {
          font-size: 20px;
          margin: 0px 0px 20px 0px;
          color: var(--ion-color-primary);
        }

        .box-subtitle {
          font-size: 18px;
          margin-left: 10px;
        }

        .box-top {
          padding: 20px;
          display: flex;
          flex-direction: column;

          .box-top-button {
            padding: 5px 10px;
            border-radius: 5px;
            border: 1px solid #dbdbdb;
            text-decoration: none;
            color: var(--ion-color-dark);
            font-size: 13px;
          }
        }

        .box-bottom {
          padding: 20px;
          border-top: 1px solid #dbdbdb;
          display: flex;
          align-items: center;

          ion-icon {
            width: 15px;
            height: 15px;
            margin-left: 10px;
          }

          a {
            color: var(--ion-color-primary);
            text-decoration: none;
            text-align: left;
            font-size: 12px;
          }
        }

        .submit {
          background-color: white;
          color: var(--ion-color-primary);
          border: 1px solid var(--ion-color-primary);
          border-radius: 5px;
          padding: 10px 40px;
          cursor: pointer;
          margin: 0px auto;
          transition: 0.3s;

          &:hover {
            background-color: var(--ion-color-primary);
            color: white;
          }
        }

        .delete {
          background-color: white;
          color: var(--ion-color-danger);
          border: 1px solid var(--ion-color-danger);
          border-radius: 5px;
          padding: 10px 40px;
          cursor: pointer;
          margin: 0px auto;
          transition: 0.3s;

          &:hover {
            background-color: var(--ion-color-danger);
            color: white;
          }
        }

        form {
          display: flex;
          flex-direction: column;

          .line {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 10px;

            label {
              font-size: 16px;
              margin-right: 20px;
            }

            input {
              border-radius: 5px;
              outline: none;
              border: 1px solid black;
              padding: 5px;
            }
          }
        }
      }
    }

    .activities-creation,
    .activities-edit {
      #header-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid #ccc;
        width: 100%;
        padding: 15px 0px;

        #header-container-logo {
          width: 20px;
          height: 20px;
          margin-left: 10px;
        }

        .header-container-activities-title {
          margin: 0px 0px 0px 10px;
        }

        #inpt-title {
          height: fit-content;
          width: 350px;
          margin-left: 10px;
          margin-top: 5px;
          border: none;
          outline: none;
          color: var(--ion-color-primary);
          font-size: 16px;

          &::placeholder {
            color: rgb(179, 178, 178);
          }
        }

        .submit,
        #edit-button {
          background-color: white;
          color: var(--ion-color-primary);
          border: 1px solid var(--ion-color-primary);
          border-radius: 5px;
          padding: 5px 20px;
          font-size: 14px;
          cursor: pointer;
          margin-right: 10px;
          transition: 0.3s;

          &:hover {
            background-color: var(--ion-color-primary);
            color: white;
          }
        }

        .delete {
          background-color: white;
          color: var(--ion-color-danger);
          border: 1px solid var(--ion-color-danger);
          border-radius: 5px;
          padding: 5px 20px;
          font-size: 14px;
          cursor: pointer;
          margin-right: 10px;
          transition: 0.3s;

          &:hover {
            background-color: var(--ion-color-danger);
            color: white;
          }
        }

        #submit-message {
          text-align: center;
          transition: 0.3s;
          margin: 0px 10px 0px auto;
          opacity: 0;
          min-height: 21px;
        }
      }
    }
  }
}

// .custom-player-nb-2 {
//   width: 320px !important;
//   height: 180px !important;
// }



// .custom-player {
//   position: relative;
//   width: 848px;
//   height: 486px;
//   background-color: var(--ion-color-dark);

//   video {
//     background-color: var(--ion-color-dark);
//     object-fit: cover;
//     width: 100%;
//     position: absolute;
//     inset: 50% auto auto 50%;
//     transform: translate(-50%, -50%);
//   }

//   .video-unavailable {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     display: flex;
//     flex-direction: column;
//     display: none;

//     ion-icon {
//       width: 50px;
//       height: 50px;
//       color: white;
//       margin: 0px auto;
//     }

//     p {
//       color: white;
//       text-align: center;
//     }
//   }

//   .video-available {
//     display: none;
//     flex-direction: column;
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     top: 0px;

//     .click-area {
//       display: flex;
//       width: 100%;
//       height: 100%;
//     }

//     .range-duration {
//       $height: 20px;
//       $thumb-height: 15px;
//       $track-height: 5px;

//       // colours
//       $upper-color: #37393a;
//       $lower-color: var(--ion-color-primary);
//       $thumb-color: var(--ion-color-primary);
//       $thumb-hover-color: var(--ion-color-primary);

//       $upper-background: linear-gradient(to bottom, $upper-color, $upper-color)
//         100% 50% / 100% $track-height no-repeat transparent;
//       $lower-background: linear-gradient(to bottom, $lower-color, $lower-color)
//         100% 50% / 100% $track-height no-repeat transparent;

//       display: block;
//       appearance: none;
//       width: 97%;
//       margin: 0px auto;
//       height: $height;
//       overflow: hidden;
//       cursor: pointer;
//       background: transparent;
//       transition: 0.3s;

//       // Webkit cannot style progress so we fake it with a long shadow
//       // on the thumb element
//       @function webkit-slider-thumb-shadow($i: 1) {
//         $val: #{$i}px 0 0 -#{($thumb-height - $track-height) / 2} #{$upper-color};
//         @if $i == 1 {
//           @for $k from 2 through 1000 {
//             $val: #{$val}, webkit-slider-thumb-shadow($k);
//           }
//         }
//         @return $val;
//       }

//       &::-webkit-slider-runnable-track {
//         width: 100%;
//         height: $height;
//         background: $lower-background;
//       }

//       &::-webkit-slider-thumb {
//         position: relative;
//         appearance: none;
//         height: $thumb-height;
//         width: $thumb-height;
//         background: $thumb-color;
//         border-radius: 100%;
//         border: 0;
//         top: 50%;
//         margin-top: (-$thumb-height/2);
//         box-shadow: webkit-slider-thumb-shadow();
//         transition: background-color 150ms;
//       }

//       // Firefox
//       &::-moz-range-track,
//       &::-moz-range-progress {
//         width: 100%;
//         height: $height;
//         background: $upper-background;
//       }

//       &::-moz-range-progress {
//         background: $lower-background;
//       }

//       &::-moz-range-thumb {
//         appearance: none;
//         margin: 0;
//         height: $thumb-height;
//         width: $thumb-height;
//         background: $thumb-color;
//         border-radius: 100%;
//         border: 0;
//         transition: background-color 150ms;
//       }

//       // Internet Exploder
//       &::-ms-track {
//         width: 100%;
//         height: $height;
//         border: 0;
//         // color needed to hide track marks
//         color: transparent;
//         background: transparent;
//       }

//       &::-ms-fill-lower {
//         background: $lower-background;
//       }

//       &::-ms-fill-upper {
//         background: $upper-background;
//       }

//       &::-ms-thumb {
//         appearance: none;
//         height: $thumb-height;
//         width: $thumb-height;
//         background: $thumb-color;
//         border-radius: 100%;
//         border: 0;
//         transition: background-color 150ms;
//         // IE Edge thinks it can support -webkit prefixes
//         top: 0;
//         margin: 0;
//         box-shadow: none;
//       }
//     }

//     .controls-bar {
//       width: 100%;
//       display: flex;
//       flex-direction: row;
//       padding: 10px;
//       align-items: center;
//       margin: 0px auto;
//       transition: 0.3s;

//       ion-icon {
//         font-size: 25px;
//         color: white;
//         cursor: pointer;
//         margin: 0px 5px;
//       }

//       .pause-button,
//       .contract-button,
//       .reload-button {
//         display: none;
//       }

//       .volume-container {
//         display: flex;
//         flex-direction: row;
//         align-items: center;

//         .range-volume {
//           $height: 25px;
//           $thumb-height: 15px;
//           $track-height: 5px;

//           // colours
//           $upper-color: #37393a;
//           $lower-color: white;
//           $thumb-color: white;
//           $thumb-hover-color: white;

//           $upper-background: linear-gradient(
//               to bottom,
//               $upper-color,
//               $upper-color
//             )
//             100% 50% / 100% $track-height no-repeat transparent;
//           $lower-background: linear-gradient(
//               to bottom,
//               $lower-color,
//               $lower-color
//             )
//             100% 50% / 100% $track-height no-repeat transparent;

//           display: none;
//           appearance: none;
//           width: 100px;
//           padding-right: 10px;
//           margin: 0;
//           height: $height;
//           overflow: hidden;
//           cursor: pointer;
//           background: transparent;

//           // Webkit cannot style progress so we fake it with a long shadow
//           // on the thumb element
//           @function webkit-slider-thumb-shadow($i: 1) {
//             $val: #{$i}px 0 0 -#{($thumb-height - $track-height) / 2} #{$upper-color};
//             @if $i == 1 {
//               @for $k from 2 through 1000 {
//                 $val: #{$val}, webkit-slider-thumb-shadow($k);
//               }
//             }
//             @return $val;
//           }

//           &::-webkit-slider-runnable-track {
//             width: 100%;
//             height: $height;
//             background: $lower-background;
//           }

//           &::-webkit-slider-thumb {
//             position: relative;
//             appearance: none;
//             height: $thumb-height;
//             width: $thumb-height;
//             background: $thumb-color;
//             border-radius: 100%;
//             border: 0;
//             top: 50%;
//             margin-top: (-$thumb-height/2);
//             box-shadow: webkit-slider-thumb-shadow();
//             transition: background-color 150ms;
//           }

//           // Firefox
//           &::-moz-range-track,
//           &::-moz-range-progress {
//             width: 100%;
//             height: $height;
//             background: $upper-background;
//           }

//           &::-moz-range-progress {
//             background: $lower-background;
//           }

//           &::-moz-range-thumb {
//             appearance: none;
//             margin: 0;
//             height: $thumb-height;
//             width: $thumb-height;
//             background: $thumb-color;
//             border-radius: 100%;
//             border: 0;
//             transition: background-color 150ms;
//           }

//           // Internet Exploder
//           &::-ms-track {
//             width: 100%;
//             height: $height;
//             border: 0;
//             // color needed to hide track marks
//             color: transparent;
//             background: transparent;
//           }

//           &::-ms-fill-lower {
//             background: $lower-background;
//           }

//           &::-ms-fill-upper {
//             background: $upper-background;
//           }

//           &::-ms-thumb {
//             appearance: none;
//             height: $thumb-height;
//             width: $thumb-height;
//             background: $thumb-color;
//             border-radius: 100%;
//             border: 0;
//             transition: background-color 150ms;
//             // IE Edge thinks it can support -webkit prefixes
//             top: 0;
//             margin: 0;
//             box-shadow: none;
//           }
//         }
//       }

//       .settings-button {
//         margin-left: auto;
//       }

//       .video-timing {
//         color: #ddd;
//         font-size: 14px;
//         margin-left: 10px;
//       }
//     }
//   }
// }

#phone-disable-portrait {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  width: 80%;

  .fa-mobile-alt {
    font-size: 50px;
    color: white;
    margin-bottom: 10px;
    animation-name: phone-rotation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  p {
    color: white;
    font-size: 20px;
    text-align: center;
  }

  @keyframes phone-rotation {
    0% {
      transform: rotate(90deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}


// @media (max-height: 400px) {
//   #phone-disable-portrait {
//     display: flex;
//   }

//   app-logged app-page > *:first-child {
//     display: none;
//   }
// }

// @media all and (display-mode : fullscreen){

//   #page-container {
//     display: flex !important;
//   }

//   #phone-disable-portrait {
//     display: none !important;
//   }
// }

.question-commentaire-container {
  .quiz-answer {
    font-weight: bold;
    color: black;
  }
  .quiz-commentary-paragraphe {
    color: black;
  }

  @media (max-width: 850px), (max-height: 850px) {
    .quiz-answer,
    .quiz-commentary-paragraphe {
      font-size: 12px;
    }
  }
}


@media (max-width : 400px ),(max-height : 600px) {
  .quiz-container {
    .question-container {
      .question-selected {
        .question-checkbox-container {
          margin: 0px !important;
        }
      }
    }
  }
  .question-commentaire-container {
    .quiz-answer,.quiz-commentary-paragraphe {
      font-size: 11px;
    }
  }
}

